/* You can add global styles to this file, and also import other style files */
@use 'sass:map';
@use 'sass:meta';

@use '@angular/material' as mat;
@use './fs-theme' as app;
@use './app/shared/components/alert-message/alert-message.component-theme' as alertMessage;

@include mat.core();

$app-primary: mat.define-palette(mat.$teal-palette, 500);
$app-accent: mat.define-palette(mat.$pink-palette, 400);
$app-warn: mat.define-palette(mat.$red-palette, 400);
$app-success: mat.define-palette(mat.$green-palette, 400);

$typography: mat.define-typography-config();
$app-primary-text: rgba(black, 0.87);

$app-light-theme: app.fs-light-theme(
  $app-primary,
  $app-accent,
  $app-warn,
  $app-success,
);

$app-dark-theme: app.fs-dark-theme(
  $app-primary,
  $app-accent,
  $app-warn,
  $app-success,
);

$successpalette: map.get($app-light-theme, success);
$successdefault: mat.get-color-from-palette($successpalette, default);

$colorconfig: mat.get-color-config($app-light-theme);

@include mat.all-component-themes($app-light-theme);
@include alertMessage.color($app-light-theme);

body {
  margin: 0;
  padding: 0;
}

button, select, html, textarea, input {
  font-family: Roboto,"Helvetica Neue",sans-serif;
}

/* Cookie Consent Styles */
div.cc-window.cc-banner {
  box-shadow: 0 0 .4em #000000;
}
a.cc-link {
  opacity: .6;
  font-weight: bold;
  padding-left: 0px;
}
a.cc-btn.cc-dismiss {  
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 4px;
  cursor: pointer;
  border-radius: 25px;           
}
a.cc-btn.cc-allow, a.cc-btn.cc-deny {  
  border: none;
  width: 100px !important;
  background-color: #6166d6 !important;
  color: #fff !important;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none !important;
  display: inline-block;
  margin: 4px 4px;
  cursor: pointer;
  border-radius: 25px;           
}

a.cc-btn.cc-allow:hover, a.cc-btn.cc-deny:hover {
  background-color: #fff !important;
  color: #6166d6 !important;
  border: 1px solid #6166d6 !important;
}

@media (max-width: 400px) {
  .cdk-overlay-pane {
    height: 100vh !important;
    width: 100vw !important;
    max-width: none !important;
    max-height: none !important;

    .mat-dialog-container {
      height: 100% !important;
      width: 100% !important;
      max-width: none !important;
      max-height: none !important;
      border-radius: 0;

      .mat-dialog-content {
        height: 100% !important;
        width: 100% !important;
        max-width: none !important;
        max-height: none !important;
      }
    }
  }
}
