@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get color palettes from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $warn-palette: map.get($color-config, 'warn');
  $success-palette: map.get($theme, 'success');
  
  .alert-success {
    color: mat.get-color-from-palette($success-palette, 900);
    background-color: mat.get-color-from-palette($success-palette, 100);
  }

  .alert-warn {
    color: mat.get-color-from-palette($warn-palette, 900);
    background-color: mat.get-color-from-palette($warn-palette, 100);
  }
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);

  .alert-font {
    font-family: mat.font-family($typography-config);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
